<template>
    <base-dialog width="763" :is-open.sync="dialog">
        <v-card-title
            class="font-weight-bold align-center justify-start"
            style="background-color: #f1eeee"
        >
            <span style="color: #78756a"
                >Edit Access for
                <span class="success--text">{{ displayName || "" }}</span></span
            >
        </v-card-title>

        <v-alert tile type="error" v-if="!!error">
            {{ error }}
        </v-alert>

        <v-card-text class="px-2">
            <v-container fluid class="d-flex justify-center">
                <v-list color="secondaryBackgroundColor">
                    <template v-for="(permission, index) in permissions">
                        <v-card color="transparent" flat :key="index">
                            <v-list-item :key="index"
                                ><v-switch
                                    inset
                                    color="accent"
                                    class="mt-0"
                                    hide-details
                                    :true-value="1"
                                    :false-value="0"
                                    v-model="form[permission.value]"
                                ></v-switch>
                                <span
                                    class="subtitle-1 font-weight-medium black--text"
                                >
                                    {{ permission.title }}</span
                                >
                            </v-list-item>
                            <template v-if="permission.children.length > 0">
                                <div class="pl-16">
                                    <template
                                        v-for="(
                                            subPermission, index2
                                        ) in permission.children"
                                    >
                                        <v-list-item :key="index2"
                                            ><v-switch
                                                inset
                                                color="accent"
                                                class="mt-0"
                                                hide-details
                                                :true-value="1"
                                                :false-value="0"
                                                v-model="
                                                    form[subPermission.value]
                                                "
                                            ></v-switch>
                                            <span
                                                class="subtitle-1 font-weight-medium"
                                            >
                                                {{ subPermission.title }}</span
                                            >
                                        </v-list-item>
                                    </template>
                                </div>
                            </template>
                        </v-card>
                    </template>
                </v-list>
            </v-container>
        </v-card-text>

        <v-card-actions class="pa-0">
            <v-container fluid>
                <v-row align="center" justify="center" class="py-1 px-2">
                    <v-col md="2" cols="12" class="px-2">
                        <v-btn
                            block
                            depressed
                            dark
                            color="#389877"
                            rounded
                            :loading="isActionStart"
                            @click="onSave"
                            >Save</v-btn
                        >
                    </v-col>
                </v-row>
            </v-container>
        </v-card-actions>
    </base-dialog>
</template>

<script>
import { UPDATE_ACCESS_CONTROL } from "@/store/modules/settings";
import BaseDialog from "@/components/Base/BaseDialog";

const defaultForm = {
    "portal-dashboard": 0,
    "portal-home": 0,
    "portal-watch": 0,
    "portal-learn": 0,
    "portal-engage": 0,
    "portal-engage-sessions": 0,
    "portal-engage-prayer-requests": 0,
    "portal-engage-daily-devotions": 0,
    "portal-engage-events-calendar": 0,
    "portal-settings": 0,
    "portal-profile-management": 0,
    "portal-profile-management-users": 0,
    "portal-profile-management-groups": 0,
    "portal-profile-management-requests": 0,
};

const defaultPermissions = [
    {
        title: "Dashboard",
        value: "portal-dashboard",
        children: [],
    },
    {
        title: "Home",
        value: "portal-home",
        children: [],
    },
    {
        title: "Watch",
        value: "portal-watch",
        children: [],
    },
    {
        title: "Learn",
        value: "portal-learn",
        children: [],
    },
    {
        title: "Engage",
        value: "portal-engage",
        children: [
            {
                title: "Sessions",
                value: "portal-engage-sessions",
            },
            {
                title: "Prayer Request",
                value: "portal-engage-prayer-requests",
            },
            {
                title: "Daily Devotions",
                value: "portal-engage-daily-devotions",
            },
            {
                title: "Events Calendar",
                value: "portal-engage-events-calendar",
            },
        ],
    },
    {
        title: "Settings",
        value: "portal-settings",
        children: [],
    },
    {
        title: "Ministry",
        value: "portal-profile-management",
        children: [
            {
                title: "Users",
                value: "portal-profile-management-users",
            },
            {
                title: "Groups",
                value: "portal-profile-management-groups",
            },
            {
                title: "Requests",
                value: "portal-profile-management-requests",
            },
        ],
    },
];

export default {
    name: "settings-access-form-dialog",

    components: { BaseDialog },

    props: {
        show: { type: Boolean, default: false, required: true },
        data: { type: [Object, null], required: false },
    },

    data() {
        return {
            dialog: this.show,
            error: null,
            isActionStart: false,
            form: Object.assign({}, defaultForm),
            permissions: [...defaultPermissions],
            userID: null,
            displayName: null,
        };
    },

    watch: {
        show(to) {
            this.dialog = to;
        },

        data(value) {
            if (value) {
                this.userID = value.id;
                this.setDisplayName(value);
                this.assignAccessControlForm(value.access_controls);
            }
        },

        dialog(value) {
            if (!value) {
                this.$emit("close");
                this.resetForm();
            }
        },
    },

    methods: {
        async onSave() {
            const payload = {
                id: this.userID,
                ...this.form,
            };
            this.isActionStart = true;
            const { success, message } = await this.$store.dispatch(
                UPDATE_ACCESS_CONTROL,
                payload
            );
            if (success) {
                this.dialog = false;
                this.$emit("onSuccess");
                this.resetForm();
                this.isActionStart = false;
                this.$notify({
                    group: "main-notifications",
                    type: "success",
                    title: "Settings",
                    text: "Access control was updated.",
                });
                return;
            }
            this.error = message;
            this.isActionStart = false;
        },

        resetForm() {
            this.error = null;
            this.form = Object.assign({}, defaultForm);
        },

        assignAccessControlForm(accessControls) {
            accessControls.forEach((control) => {
                this.form[control.slug] = control.have_access ? 1 : 0;
            });
        },

        setDisplayName({ first_name, last_name }) {
            if (first_name && last_name) {
                this.displayName = `${last_name}, ${first_name}`;
            }
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.close-icon {
    transition: 0.3s;
    border-radius: 50px !important;
    margin-top: 20px;
    margin-right: 15px;
    padding: 3px;

    &:hover {
        background-color: #2c423d;
        color: white;
    }
}

.input-button-row {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: row;
}
</style>
